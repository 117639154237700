import React, {Component} from "react"
import {StaticQuery, graphql, Link} from "gatsby"
import {Container} from "reactstrap"
import styled from "styled-components"
import {media} from "utils/Media"
import PreviewModal from '../shared/PreviewModal'
import ImageCarousel2 from './ImageCarousel2'


const IntroBg = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 0 2rem;

     @media ${media.sm} {
        padding: 0 2rem 3rem;
     }

`

const IntroContent = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 4;
    
    .container {
        @media ${media.xl} {
            max-width: 1400px !important;
        }
    }

    .narrow {
        max-width: 681px;
    }


    h2 {
        font-family: ${props => props.theme.font.family.special};
        color: ${props => props.theme.colors.navy};
        margin: 0;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
`

const Content = styled.div`
    
    @media ${media.xl} {
        //display: flex;
        //justify-content: center;
        //align-items: center;
        //
        //.flex-helper {
        //    width: 50%;
        //}
        
    }
`

const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
                allChristmasScheduleJson {
                    edges {
                        node {
                            date
                            time
                        }
                    }
                }
                BgImageXs: file(relativePath: { eq: "bg-performances-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }

                BgImageSm: file(relativePath: { eq: "bg-performances-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }

                BgImageLg: file(relativePath: { eq: "bg-performances-1200.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1200, quality: 100)
                    }
                }

                BgImageXl: file(relativePath: { eq: "bg-performances-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }


               teaser: file(relativePath: { eq: "CastAndCreativePage/Frozen_JUL20_Full-Cast-Grid_1159x1920_AW.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1200, quality: 100)
                    }
                }

            }
		`}
        render={data => (
            <Intro id={props.id} data={data}/>
        )}
    />
)

class Intro extends Component {
    constructor(props) {
        super(props);

        this.state = {
            previewModalOpen: false,
        }
    }

    togglePreviewModal = (e, src) => {
        e.preventDefault();

        this.setState({
            src: src,
            previewModalOpen: !this.state.previewModalOpen
        });
    }

    render() {
        return (
            <IntroBg>
                <IntroContent>
                    <Container fluid={true}>
                        <Container>
                            <Content>
                                    <ImageCarousel2/>
                                <p className="text-center smaller color--black narrow mb-2 mx-auto">Set the magic free with Disney&rsquo;s spectacular FROZEN. The award-winning musical brings to life a world of sumptuous beauty, humour and iconic music in a jaw-dropping production to melt hearts of all ages.</p>
                                <p className="text-center smaller color--black narrow mb-2 mx-auto">When Queen Elsa&rsquo;s hidden powers plunge the land of Arendelle into an eternal winter, her sister Anna sets out to find her and save the kingdom before it&rsquo;s too late. But as the storm rages on, both sisters must learn that only an act of true love can thaw a frozen heart.</p>
                                <p className="text-center smaller color--black narrow mb-2 mx-auto">FROZEN will defy all expectations on an unforgettable journey packed with thrilling surprises, hilarious characters and soaring music, including twelve new songs written especially for the show, alongside all the classics such as &lsquo;For the First Time in Forever&rsquo;, &lsquo;Do You Want to Build a Snowman?&rsquo; and the worldwide smash-hit, &lsquo;Let it Go&rsquo;. Brought to the stage with astonishing special effects by an award-winning creative team, FROZEN is the awe-inspiring musical sensation at the magnificent Theatre Royal Drury Lane. Once you&rsquo;ve seen it, you&rsquo;ll never let it go.</p>
                            </Content>
                        </Container>
                    </Container>
                </IntroContent>

                <PreviewModal toggleModal={this.togglePreviewModal} modalOpen={this.state.previewModalOpen}/>
            </IntroBg>
        )
    }
}

export default Query
