import React, {Component} from "react"
import {StaticQuery, graphql, Link} from "gatsby"
import {Container} from "reactstrap"
import styled from "styled-components"
import {media} from "utils/Media"
import PreviewModal from '../shared/PreviewModal'
import { StaticImage } from 'gatsby-plugin-image';


const NominationsBg = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0;

     @media ${media.sm} {
        padding: 0 2rem 3rem;
     }

`

const NominationsContent = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 4;
    text-align: center;

    .container {
        @media ${media.xl} {
            max-width: 1400px !important;
        }
    }

    .narrow {
        max-width: 681px;
    }


    h2 {
        font-family: ${props => props.theme.font.family.special};
        color: ${props => props.theme.colors.navy};
        margin: 0;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    h3 {
        font-family: ${props => props.theme.font.family.special};
        color: ${props => props.theme.colors.navy};
        margin: 0.5rem auto 0;
        text-transform: uppercase;
        /* padding: 2rem 0; */
        margin-top: 1.25rem;

        @media (max-width: 575px) {
            font-size: 1.25rem;
            line-height: 100%;
        }

        .bigger {
            font-size: 4rem;
            line-height: 100%;
            display: block;
            margin-bottom: 1rem;

            @media (min-width: 992px) {
                font-size: 5rem;
                margin: 0;
                display: inline-block;
            }
        }
    }
    .copy {
        font-size: 16px;
        @media ${media.md} {
            font-size: 22px;
            max-width: 850px;

        }
    }

`

const Content = styled.div`
    text-align: center;
    .copy {
        width: 85%;
        margin: 1rem auto 2rem auto;
    }
    strong {
        font-size: 20px;
        text-transform: uppercase;
    }
    .line {
        display: inline-block;
        border: 1px solid #63DBE7;
        transform: matrix(-1, 0, 0, -1, 0, 0);
        position: relative;
        z-index: 1;
        width: 100%;

        &:after {
            content: "";
            position: absolute;
            z-index: 2;
            left: 15px;
            top: 3px;
            border: 1px solid #004684;
            width: 100%;
        }
        
        &.line-left {
            &:after {
                left: initial;
                right: 15px;
            }
        }
    }

    .whatsonstage {
        max-width: 150px;
        @media ${media.md} {
            max-width: 200px;

        }
    }

    h4.special {
        font-family: Turquoise W05 Inline;
        color: #004684;
        margin-bottom: 0;
    }

    h4 {
        font-family: "Proxima Nova W05 Regular", Arial, sans-serif;
        font-weight: 300 !important;
    }

    &.flex {
        display: flex;
        justify-content: center;
        .flex-helper {
            width: 100%;
        }
    }

   
`

const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
                allChristmasScheduleJson {
                    edges {
                        node {
                            date
                            time
                        }
                    }
                }
                BgImageXs: file(relativePath: { eq: "bg-performances-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }

                BgImageSm: file(relativePath: { eq: "bg-performances-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }

                BgImageLg: file(relativePath: { eq: "bg-performances-1200.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1200, quality: 100)
                    }
                }

                BgImageXl: file(relativePath: { eq: "bg-performances-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }


               teaser: file(relativePath: { eq: "CastAndCreativePage/Frozen_JUL20_Full-Cast-Grid_1159x1920_AW.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1200, quality: 100)
                    }
                }

            }
		`}
        render={data => (
            <Nominations id={props.id} data={data}/>
        )}
    />
)

class Nominations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            previewModalOpen: false,
        }
    }

    togglePreviewModal = (e, src) => {
        e.preventDefault();

        this.setState({
            src: src,
            previewModalOpen: !this.state.previewModalOpen
        });
    }

    render() {
        return (
            <NominationsBg>
                <NominationsContent>
                    <Container fluid={true}>
                        <Container>
                            <Content className="align-items-center flex d-lg-flex">
                                <div className="flex-helper">
                                    <span className="line line-left"/>
                                </div>
                                <div className="flex-helper">
                                     <StaticImage className="whatsonstage" src="../../images/HomePage/whatsonstage-logo.png" alt="Whats on stage" />
                                </div>
                                <div className="flex-helper">
                                    <span className="line line-right"/>
                                </div>
                            </Content>

                            <h3 className="h2 pb-2 pb-md-5">WINNER OF 7 WHATSONSTAGE AWARDS</h3>

                            <Content className="align-items-center flex d-block d-lg-flex">
                                <div className="flex-helper">
                                    <h4 className="special">BEST DIRECTION</h4>
                                    <p>Michael Grandage</p>

                                    <h4 className="special">BEST MUSICAL DIRECTION</h4>
                                    <p>Stephen Oremus</p>


                                </div>
                                <div className="flex-helper">
                                    <h4 className="special">BEST CHOREOGRAPHY</h4>
                                    <p>Rob Ashford</p>

                                    <h4 className="special">BEST SET DESIGN</h4>
                                    <p>Christopher Oram</p>

                                    <h4 className="special">BEST COSTUME DESIGN</h4>
                                    <p>Christopher Oram</p>
                                </div>
                                <div className="flex-helper">
                                    <h4 className="special">BEST VIDEO DESIGN</h4>
                                    <p>Finn Ross</p>

                                    <h4 className="special">BEST GRAPHIC DESIGN</h4>
                                    <p>Bob King Creative</p>
                                </div>
                            </Content>

                            <Content className="align-items-center d-none d-md-block flex d-lg-flex">
                                <div className="flex-helper">
                                    <span className="line"/>
                                </div>
                            </Content>
                        </Container>
                    </Container>
                </NominationsContent>

                <PreviewModal toggleModal={this.togglePreviewModal} modalOpen={this.state.previewModalOpen}/>
            </NominationsBg>
        )
    }
}

export default Query
