import React, {Component} from 'react'
import {StaticQuery, graphql} from 'gatsby'
import Slider from 'react-slick'
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";
import styled, { css } from "styled-components"
import {media} from "utils/Media"
import nextBtn from '../../images/arrow.jpg'
import prevBtn from '../../images/arrow-prev.jpg'
import arrowVector from '../../images/arrow-vector.svg'
import arrowVectorRight from '../../images/arrow-vertor-right.svg'

const SlickArrowLeft = ({currentSlide, slideCount, ...props}) => (
    <button
        {...props}
        className={
            "slick-prev slick-arrow" +
            (currentSlide === 0 ? " slick-disabled" : "")
        }
        aria-hidden="false"
        aria-disabled={currentSlide === 0}
        type="button"
        tabIndex={0}
    >
        Previous
        <img className="vector" src={arrowVector} alt="" />
    </button>
);
const SlickArrowRight = ({currentSlide, slideCount, ...props}) => (
    <button
        {...props}
        className={
            "slick-next slick-arrow" +
            (currentSlide === slideCount - 1 ? " slick-disabled" : "")
        }
        aria-hidden="false"
        aria-disabled={currentSlide === slideCount - 1}
        type="button"
        tabIndex={0}
    >
        Next
        <img className="vector" src={arrowVectorRight} alt="" />
    </button>
);
const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    // autoplaySpeed: 4500,
    accessibility: true,
    slidesToShow: 1,
    fade: false,
    slidesToScroll: 1,
    lazyLoad: false,
    adaptiveHeight: false,
    // autoplay: true,
    prevArrow: <SlickArrowLeft/>,
    nextArrow: <SlickArrowRight/>,
    centered: true
};

const Wrapper = styled.div`
    position: relative;
    max-width: 1400px;
    margin: 0 auto 2rem;
    width: 100%;
    /* max-height: 500px; */
    @media ${media.md} {
        margin: 2rem auto;
        width: calc(100% - 8rem);
    }
`

const MainImageWrapper = styled.div`
    margin: 2rem auto;
    text-align: center;
    display: none;
    
    img {   
        // max-height: 460px;
        @media ${media.sm} {
            max-height: 308px;
        }
        @media ${media.md} {
            max-height: 340px;
        }        
        @media ${media.lg} {
            max-height: 449px;
        }
        @media ${media.xl} {
            max-height: 335px;
        }
    }
`

const PhotoWrapper = styled.div`
    
    .imageWrapper {
        text-align: center;
        
        @media ${media.md} {
            margin: 0 1rem;
        }
        
        img {
            margin: 0 auto;
            @media ${media.sm} {
                max-height: 308px;
            }
            @media ${media.md} {
                max-height: initial;
            }        
        }
    }
`

const GallerySlider = styled(Slider)`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 75%;
    margin: 0 auto;
    
    @media ${media.sm} {
        max-width: 100%;
    }
    .slick-track {
        display: flex;
        align-items: center;
    }
    
    .slick-item {
        // width: calc(33% - 0.25rem);
        
        @media ${media.md} {
            width: 100%;
        }
    }
    .slick-slider {
        padding: 60px 0;
    }
    
    ${PhotoWrapper} {
        h3, p {
            text-transform: uppercase;
        }
        
        h3 {
            color: ${props => props.theme.colors.yellow};
            margin-bottom: 0;
        }
    }
    
    .slick-prev, .slick-next {
        width: 40px;
        height: 40px;
        top: 50%;
        bottom: initial;
        transform: translateY(-50%);
        z-index: 2;
        
        &:hover::before {
            opacity: 1;
        }
        &:hover {
            .vector {
                opacity: 1;
            }
        }
        
        .vector {
            position: absolute;
            z-index: 3;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0;
            transform: translate(-50%,-50%);
            width: 10px;
            left: calc(50% + 2px);
        }
    }
    
    .slick-next {
        right: -3rem;
        background: url(${nextBtn}) no-repeat center center;
        background-size: contain;
        
        &:before {
            content: '';
            opacity: 0;
            transition: opacity 0.2s linear;
            background: linear-gradient(135deg, #9A8AFF 0%, #3B1293 48.96%, #000355 100%);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
    }
    
    .slick-prev {
        left: -3rem;
        background: url(${prevBtn}) no-repeat center center;
        background-size: contain;
        
        &:before {
            content: '';
            transition: opacity 0.2s linear;
            background: linear-gradient(135deg, #9A8AFF 0%, #3B1293 48.96%, #000355 100%);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 1;
        }
        
        .vector {
            left: calc(50% - 2px);
        }
    }
`

class GalleryCarousel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            videoId: '',
            isOpen: false,
            modalOpen: false,
        }
    }

    toggleModal = (e) => {
        e.preventDefault();

        this.setState({
            modalOpen: !this.state.modalOpen
        });
    };

    render() {
        const {isOpen, photoIndex} = this.state

        const itemsForGallery = this.props.data.images.edges

        let images = []

        const GalleryItem = (props) => {
            const { node, index } = props
            const photo = node.node

            return(
                <PhotoWrapper
                    // role="button" aria-pressed="false"
                    // tabIndex={index}
                    // onClick={() => this.setState({photoIndex: index, isOpen: true})}
                    // onKeyDown={() => this.setState({photoIndex: index, isOpen: true})}
                >
                    <div className="imageWrapper">
                        {photo.image &&
                        <GatsbyImage
                            image={photo.image.childImageSharp.gatsbyImageData}
                            alt=""
                            title=""
                            className="mb-2 "
                        />
                        }
                    </div>
                </PhotoWrapper>
            )
        }

        const galleryItems = itemsForGallery.map((node, i) => {
            images.push(node.node.src)
            return(
                <div key={i} className="slick-item">
                    <GalleryItem index={i} node={node} />
                </div>
            )
        })

        return (
            <Wrapper>
                <GallerySlider {...settings}>
                    {galleryItems}
                    <div className="slick-item">
                        <div className="imageWrapper">
                            <StaticImage src="../../images/HomePage/gallery/1280X720_2.jpg" alt="" />
                        </div>
                    </div>
                    <div className="slick-item">
                        <div className="imageWrapper">
                            <StaticImage src="../../images/HomePage/gallery/FROZEN_1140x960px-2.jpg" alt="" />
                        </div>
                    </div>
                </GallerySlider>
            </Wrapper>
        )
    }
}

export default props => (
    <StaticQuery
        query={graphql`
        query {
            images:allPhotos2Json {
                    edges {
                        node {
                            id
                            image {
                                childImageSharp {
                                    gatsbyImageData(width: 1920, quality: 100)
                                }
                            }
                            alt
                        }
                    }
                }
            # allHomepagePhotosJson {
            #     edges {
            #         node {
            #             id
            #             image {
            #                 childImageSharp {
            #                     gatsbyImageData(
            #                         width: 1920,
            #                         quality: 100
            #                     )
            #                 }
            #             }
            #         }
            #     }
            # }

        }`}
        render={data => <GalleryCarousel data={data} {...props} />}
    />
)
