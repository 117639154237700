import React, { Component } from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { Container } from "reactstrap";
import styled from "styled-components";
import { media } from "utils/Media";
import PreviewModal from "../shared/PreviewModal";
import { StaticImage } from "gatsby-plugin-image";

const WosAwardsStyles = styled.section`
  .wrapper {
    width: 95%;
    margin: 2rem auto;
    max-width: 1440px;
    text-align: center;
    .awards {
      h3 {
        font-family: ${props => props.theme.font.family.special};
        color: ${props => props.theme.colors.navy};
        text-transform: uppercase;
        margin: 1rem 0;
        font-size: 2rem; 
        line-height: 2.25rem;
        @media ${media.lg} {
      font-size: 40px!important;
    } 
      }
      p {
        width: 90%;
        margin: 0 auto;
        max-width: 840px;
      }
      .image {
        width: 80%;
        margin: 2rem auto;
        @media ${media.md} {
      width: 900px;
    }
      }
    }
  }
`;

const Content = styled.div`
  text-align: center;
  .copy {
    width: 100%;
    margin: 1rem auto 2rem auto;
  }
  strong {
    font-size: 20px;
    text-transform: uppercase;
  }
  .line {
    display: inline-block;
    border: 1px solid #63dbe7;
    transform: matrix(-1, 0, 0, -1, 0, 0);
    position: relative;
    z-index: 1;
    width: 100%;

    &:after {
      content: "";
      position: absolute;
      z-index: 2;
      left: 15px;
      top: 3px;
      border: 1px solid #004684;
      width: 100%;
    }

    &.line-left {
      &:after {
        left: initial;
        right: 15px;
      }
    }
  }

  .whatsonstage {
    max-width: 100px;
    position: relative; 
    z-index: 10;
    @media ${media.sm} {
      max-width: 120px;
    }
    @media ${media.md} {
      max-width: 170px;
    }
  }

  h4.special {
    font-family: Turquoise W05 Inline;
    color: #004684;
    margin-bottom: 0;
  }

  h4 {
    font-family: "Proxima Nova W05 Regular", Arial, sans-serif;
    font-weight: 300 !important;
  }
  .button {
    margin: 0 auto;
    text-align: center;
  }
  a {
    width: 150px;
    display: block;
    margin: 0 auto;
    @media ${media.md} {
      width: 180px;
    }
    @media ${media.xl} {
      width: 220px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-weight: 800;
      }
    }
  }
  .middle-image {
    max-width: 250px!important;
  }
  .middle-button {
    max-width: 300px!important;
  }
  &.flex {
    display: flex;
    justify-content: center;
    .flex-helper {
      width: 100%;
    }
  }
`;

export default function WosAwards() {
  return (
    <WosAwardsStyles>
      <div className="wrapper">

      
      <Content className="align-items-center flex d-lg-flex">
        <div className="flex-helper">
          <span className="line line-left" />
        </div>
        <div className="flex-helper middle-image">
          <StaticImage
            className="whatsonstage"
            src="../../images/new-wos.png"
            alt="Whats on stage"
          />
        </div>
        <div className="flex-helper">
          <span className="line line-right" />
        </div>
      </Content>
      <div className="awards">
        <h3>Vote for Laura Dawkes in the WhatsOnStage Awards!</h3>
        <p>
          Our wonderful Anna, Laura Dawkes, has been nominated for Best
          Professional Debut at the WhatsOnStage Awards. Laura won hearts from
          the moment she stepped in the audition room, nabbing the role straight
          out of drama school and warming our hearts ever since. Show some
          support by voting below!
        </p>
        <div className="image">
        <StaticImage
            className="whatsonstage"
            src="../../images/wos-image.png"
            alt="Whats on stage"
          />
        </div>
      </div>
      <Content className="align-items-center flex d-lg-flex">
        <div className="flex-helper">
          <span className="line line-left" />
        </div>
        <div className="flex-helper middle-button">
          <div className="button">
            <a href="https://awards.whatsonstage.com/24th-annual-whatsonstage-awards-vote-now/" className="btn btn--new"><span>VOTE HERE</span></a>
          </div>
        </div>
        <div className="flex-helper">
          <span className="line line-right" />
        </div>
      </Content>
      </div>
    </WosAwardsStyles>
  );
}
