import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { media } from "utils/Media";

import Cookies from "js-cookie";

const HyggeModalStyles = styled.div`
  @keyframes slide-in-bottom {
    0% {
      transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  width: 100%;
  height: 100vh;
  background: rgb(0, 49, 153, 0.25);
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  section {
    animation: slide-in-bottom 0.5s 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    max-width: 500px;
    position: relative;
    width: 90%;
    padding: 2.5rem 0.5rem;
    /* height: 90%; */
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    div {
      width: 90%;
      margin: 0 auto;
      p {
        text-align: center;
        strong {
          font-size: 1.5rem;
          @media ${media.md} {
            font-size: 2rem;
          }
        }
        @media ${media.md} {
          font-size: 1.3rem;
        }
      }
      .smaller {
        font-size: 0.8rem;
      }
    }
  }
`;

const ButtonClose = styled.button`
  position: absolute;
  background-color: transparent;
  z-index: 10;
  right: 1rem;
  top: 1rem;
  width: 20px;
  height: 20px;
  border: 2px solid #81e0ff;
  cursor: pointer;

  /* @media (min-width: 992px) {
        width: 30px;
        height: 30px;
        border: 2px solid #fff;
        right: -2rem;
        top: -2rem;
    } */

  &:before {
    content: "";
    position: absolute;
    width: 80%;
    height: 2px;
    background-color: #81e0ff;
    transform: translate(-50%, -50%) rotate(-45deg);

    /* @media (min-width: 992px) {
            background-color: #fff;
        } */
  }

  &:after {
    content: "";
    position: absolute;
    width: 80%;
    height: 2px;
    background-color: #81e0ff;
    transform: translate(-50%, -50%) rotate(45deg);

    /* @media (min-width: 992px) {
            background-color: #fff;
        } */
  }
`;

const HyggeModal = () => {
  const [open, setOpen] = useState(false);
  const test = Cookies.get('signUpModal') 
  console.log(test)
  useEffect(() => {
    setTimeout(() => setOpen(true), 4000);
    // if(!Cookies.get('signUpModal')) {
    //   const inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000);
    //   Cookies.set('signUpModal', true, {
    //     expires: inFifteenMinutes
    // })
    // }
  }, []);
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else if (!open) {
      document.body.style.overflow = "auto";
    }
  }, [open]);
  return open ? (
    <HyggeModalStyles onClick={() => setOpen(false)}>
      <section>
        <ButtonClose onClick={() => setOpen(false)}>
          <span className="sr-only">Close</span>
        </ButtonClose>
        <div>
          <p>
            <strong>Want to hear more about Disney On Stage? 
</strong>
          </p>
          <p>
          Sign up now for news about our hit West End and touring musicals, upcoming productions, and loads more! 
          </p>
          <a
            href="https://www.disneyonstage.co.uk/sign-up?int_cmp=ftm-dwt:oth:dos-variety-sign-up:mxdtg:::::txtx"
            target="_blank"
            className="btn btn--new d-flex mx-auto text-uppercase my-0"
          >
            <span>SIGN UP</span>
          </a>
        </div>
      </section>
    </HyggeModalStyles>
  ) : (
    ""
  );
};

export default HyggeModal;
