import React, {Component} from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import {media} from "utils/Media"
import star from "../../images/star-snowflake-blue.svg"


import { Swiper, SwiperSlide } from "swiper/react";

import { Autoplay } from 'swiper/modules';


// Import Swiper styles
import "swiper/css";
import 'swiper/css/navigation';


const StyledSwiper = styled(SwiperSlide)`
    display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
`

const QuoteSlider = styled(Slider)`
    .carouselItem {
        display: flex !important;
        justify-content: center;
        align-items: center;
        
        @media ${media.md} {
            min-height: 230px;
        }
        
        @media ${media.lg} {
            min-height: 272px;
        }
    }
    
    .slick-initialized .slick-slide {
        display: flex;
        justify-content: center;
    }
    
    .slick-slide .carouselItem, .slick-slider, .slick-track, .slick-list {
        height: 100%;
    }
    
    .slick-slide .carouselItem {
        position: relative;
        opacity: 0;
    }

    .slick-active .carouselItem {
        animation: fade 4s linear 0.1s;
    }
    
    @keyframes fade {
        0% {
            opacity: 0;
        }
        20% {
            opacity: 1;
        }
        80% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`

const Content = styled.div`
    text-align: center;
    .starWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        
        .star {
            width: 42px;
            
            @media ${media.md} {
                width: 60px;
            }
        }
    }
    
    h3 {
        font-family: ${props => props.theme.font.family.special};
        color: ${props => props.theme.colors.navy};
        margin: 0.5rem auto 0;
        text-transform: uppercase;
        @media (max-width: 575px) {
            font-size: 1.25rem;
            line-height: 100%;
        }
    }
    
    p {
        font-family: ${props => props.theme.font.family.bold};
        font-weight: bold;
        color: ${props => props.theme.colors.navy};
        margin: 0;
    }
`

export default function QuoteCarouselItem({ data }) {
    const getStars = (stars) => {
        let html = []
        for (let i = 1; i <= stars; i++) {
            html.push(<img key={i} src={star} className="star" alt="Star"/>)
        }
        return html
    }
  return (
    <Swiper className="mySwiper" centeredSlides={true}
    modules={[Autoplay]}
    autoplay={{
      delay: 2500,
      disableOnInteraction: false,
      autoHeight: true

    }}>
                {data.map((item, i ) => {
                    return (
                        <SwiperSlide className="carouselItem" key={i}>
                        <Content>
                            <div className="starWrapper">
                                {getStars(item.node.stars)}
                            </div>
                            <h3 className="h2">
                                {item.node.copy}
                            </h3>
                            <p>{item.node.publisher}</p>
                        </Content>
                    </SwiperSlide>
                    )
                })}
            </Swiper>
  )
}
