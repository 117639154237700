import React from 'react'
import {StaticQuery, graphql} from 'gatsby'
import QuoteCarouselItem from './QuoteCarouselItem'

const QuoteCarousel = ({data}) => (
        <QuoteCarouselItem data={data.allQuoteCarouselJson.edges}/>
);

export default props => (
    <StaticQuery
        query={graphql`
        query {
            allQuoteCarouselJson {
                edges {
                    node {
                        id
                        stars
                        copy
                        publisher
                    }
                }
            }
        }`}
        render={data => <QuoteCarousel data={data} {...props} />}
    />
)
